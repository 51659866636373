import React, { useEffect } from 'react'
import { graphql } from "gatsby" 
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Comp from '../images/pc-icon.svg'
import Tablet from '../images/drawing-tablet.svg'
import Webdes from '../images/web-designer.svg'
import Layout from "../components/layout"
import { HeaderAnim } from "../components/animscripts/page-header-anim";


const Services = ({data}) => {
  useEffect(() => {
    HeaderAnim();
  }, []);
 return <Layout>
        <div className="main__services__container">
            <div className="cross__wrapper cross__wrapper__projects__top cross--shape"></div>
                    <h3 className="page__header__txt header--anim">Serv<span>ices</span></h3>
            <div className="services__content">
              <div className="services__content__info">
                  <div className="services__content__info__top">
                    <div className="box--text--reveal">
                      <p className="services__content__info__top__txt text--reveal--1">
                        <span className="services__content__info__top__txt--highlight ">Inspired</span>&nbsp;
                        solutions, tailored to meet your expectation and business needs 
                      </p>
                    </div>
                  </div>
                <div className="services__content__info__desktop in--from--below">
                  <h4 className="header__tertiary">Why me?</h4>
                  <p className="paragraph__tertiary">Sure, anyone can talk the talk, but I'm all about walking the walk. My passion lies in turning ideas into tangible success stories that not only boost your bottom line but also leave you saying, "Wow, we did it!"</p>
    <p className="paragraph__tertiary">In my toolkit, you'll find skills that go beyond the ordinary - from designing and developing to testing multiple web-based applications. I'm not just a Front-End Developer; I'm your partner in digital innovation, ready to turn your aspirations into a visually stunning and high-performing reality.
    <br /><br />
Ready to elevate your online presence? Let's embark on this journey together!</p>
                </div>
                <br/>
                <div className="services__content__info__desktop in--from--above ">
                  <h4 className="header__tertiary">Project Examples</h4><br/>
                  <p className="paragraph__tertiary"><b>Unsworth Sugden Group /  2022</b> <hr/></p>
                  <p className="paragraph__tertiary">&#8729; Crafted in the WordPress wonderland!</p>
                  <p className="paragraph__tertiary">&#8729; Collaborated seamlessly with clients and account handlers to bring projects to life - meeting requirements, achieving goals, and ensuring desired functionality.</p>
                  <p className="paragraph__tertiary">&#8729; Teamed up with the support/dev squad, tackling challenges head-on to create smart, robust solutions.</p><p className="paragraph__tertiary">&#8729; Project link <a className="project__link" href="https://bikramyogaleicester.com/" target="_blank">BIKRAM YOGA LEICESTER </a></p>
                </div>
               
                <div className="services__content__info__desktop in--from--above margin--top--medium">
                  <p className="paragraph__tertiary"><b>ShopWired / Jan 2021</b> <hr/></p>
                  <p className="paragraph__tertiary">&#8729; Developed and integrate themes into Twig template engine.</p>
                  <p className="paragraph__tertiary">&#8729; Commuincating with clients to meet project requirements, goals and desired functionality.</p>
                  <p className="paragraph__tertiary">&#8729; Worked closely with the support/dev team to resolve issues and create smart/robust solutions.</p><p className="paragraph__tertiary">&#8729; Project link <a className="project__link" href="https://www.roses.co.uk/" target="_blank">Harkness Roses</a></p>
                </div>
                
              </div>
              {/* services card container section below */}
            <div className="services__cards__container ">
                <details className="service__card service__card__dev cards--in cursor-hover">
                    <summary>
                    <div className="service__card__dev__header service__card__header">
                        <img className="service__card__dev__header__icon service__card__header__icon" src={Comp} alt="web development icon"/>
                        <h3 className="service__card__dev__header__title">Web development skills</h3>
                    </div>
                        <p className="service__card__info">Extend your reach and explore a wide range of opportunities.</p>
                    </summary>
                    <div className="service__card__dropdown service__card__dev__dropdown">
                        <hr className="service__card__line"></hr>
                        <ul>
                            <li><Img fixed={data.devIcon1.childImageSharp.fixed} alt="Vue.js icon"/> <span>React.js</span></li>
                            <li><Img fixed={data.devIcon2.childImageSharp.fixed} alt="react.js icon"/><span>Vue.js</span></li>
                            <li><Img fixed={data.devIcon3.childImageSharp.fixed} alt="Html icon"/> <span>HTML</span></li>
                            <li><Img fixed={data.devIcon4.childImageSharp.fixed} alt="css icon"/> <span>CSS</span></li>
                            <li><Img fixed={data.devIcon5.childImageSharp.fixed} alt="css icon"/> <span>Javascript</span></li>
                            <li><Img fixed={data.devIcon6.childImageSharp.fixed} alt="Python icon"/> <span>Python</span></li>
                            <li><Img fixed={data.devIcon7.childImageSharp.fixed} alt="Sass icon"/> <span>Sass/Scss</span></li>
                            <li><Img fixed={data.devIcon8.childImageSharp.fixed} alt="Git icon"/> <span>Git</span></li>
                            <li><Img fixed={data.devIcon9.childImageSharp.fixed} alt="VSC icon"/> <span>Visual Studio Code</span></li>
                            <li><Img fixed={data.devIcon10.childImageSharp.fixed} alt="Netlify icon"/> <span>Netlify CMS</span></li>
                            <li><Img fixed={data.devIcon11.childImageSharp.fixed} alt="ShopWired icon"/> <span>ShopWired</span></li>
                            <li><Img fixed={data.devIcon12.childImageSharp.fixed} alt="Twig icon"/> <span>Twig</span></li>
                        </ul>
                    </div>
                </details>
                <details className="service__card service__card__gdesigner cards--in cursor-hover">
                    <summary>
                        <div className="service__card__gdesigner__header service__card__header">
                            <img className="service__card__gdesigner__header__icon service__card__header__icon"src={Tablet} alt="graphics designer icon"/>
                            <h3 className="service__card__gdesigner__header__title">Graphic design skills</h3>
                        </div>
                        <p className="service__card__info">Creativity that helps you build your brand and business stand out.</p>
                    </summary>
                    <div className="service__card__dropdown service__card__gdesigner__dropdown">
                        <hr className="service__card__line"></hr>
                        <ul>
                            <li><Img fixed={data.graphics1.childImageSharp.fixed} alt="Vue.js icon"/><span>Adobe Photoshop</span></li>
                            <li><Img fixed={data.graphics2.childImageSharp.fixed} alt="react.js icon"/><span>Adobe Illustrator</span></li>
                            <li><Img fixed={data.graphics3.childImageSharp.fixed} alt="Html icon"/><span>Adobe AfterEffects</span></li>
                            <li><Img fixed={data.graphics4.childImageSharp.fixed} alt="css icon"/><span>Krita</span></li>
                        </ul>
                    </div>
                </details>
                <details className="service__card service__card__wdesigner cards--in cursor-hover">
                    <summary>
                    <div className="service__card__wdesigner__header service__card__header">
                        <img className="service__card__wdesigner__header__icon service__card__header__icon"src={Webdes} alt="web designer icon"/>
                        <h3 className="service__card__wdesigner__header__title">Web design skills</h3>
                    </div>
                    <p className="service__card__info">Give delightful experience to your visitors with a meticulos website design.</p>
                    </summary>
                    <div className="service__card__dropdown service__card__wdesigner__dropdown">
                        <hr className="service__card__line"></hr>
                        <ul>
                            <li><Img fixed={data.web1.childImageSharp.fixed} alt="css icon"/><span>Adobe XD</span></li>
                            <li><Img fixed={data.web2.childImageSharp.fixed} alt="css icon"/><span>Figma</span></li>
                        </ul>
                    </div>
                </details>
            </div>
            </div>
                <div className="service__content__info__mobile">
                  <h4 className="service__content__info__mobile__header header__tertiary">Why me?</h4>
                  <p className="paragraph__tertiary">Sure, anyone can talk the talk, but I'm all about walking the walk. My passion lies in turning ideas into tangible success stories that not only boost your bottom line but also leave you saying, "Wow, we did it!"</p>
                <p className="paragraph__tertiary">In my toolkit, you'll find skills that go beyond the ordinary - from designing and developing to testing multiple web-based applications. I'm not just a Front-End Developer; I'm your partner in digital innovation, ready to turn your aspirations into a visually stunning and high-performing reality.
<br /><br />
Ready to elevate your online presence? Let's embark on this journey together!</p>
                </div>
                <br/>
                <div className="service__content__info__mobile">

                  <h4 className="header__tertiary">Project Examples</h4><br/>
                  <p className="paragraph__tertiary"><b>Unsworth Sugden Group /  2022</b> <hr/></p>
                  <p className="paragraph__tertiary">&#8729; Crafted in the WordPress wonderland!</p>
                  <p className="paragraph__tertiary">&#8729; Collaborated seamlessly with clients and account handlers to bring projects to life - meeting requirements, achieving goals, and ensuring desired functionality.</p>
                  <p className="paragraph__tertiary">&#8729; Teamed up with the support/dev squad, tackling challenges head-on to create smart, robust solutions.</p><p className="paragraph__tertiary">&#8729; Project link <a className="project__link" href="https://bikramyogaleicester.com/" target="_blank">BIKRAM YOGA LEICESTER </a></p>
                </div>

                <div className="service__content__info__mobile margin--top--medium">
                  
                  <p className="paragraph__tertiary"><b>ShopWired / Jan 2021</b> <hr/></p>
                  <p className="paragraph__tertiary">&#8729; Developed and integrate themes into Twig template engine.</p>
                  <p className="paragraph__tertiary">&#8729; Commuincating with clients to meet project requirements, goals and desired functionality.</p>
                  <p className="paragraph__tertiary">&#8729; Worked closely with the support/dev team to resolve issues and create smart/robust solutions.</p><p className="paragraph__tertiary">&#8729; Project example <a className="project__link" href="https://www.roses.co.uk/" target="_blank">Harkness Roses</a></p>
                </div>
        </div>
  </Layout>
};

Services.propTypes = {
    data: PropTypes.object.isRequired,
};

export const query = graphql`
{
    devIcon1: file(relativePath: {eq: "services-icons/dev-1.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    devIcon2: file(relativePath: {eq: "services-icons/dev-2.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    devIcon3: file(relativePath: {eq: "services-icons/dev-3.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    devIcon4: file(relativePath: {eq: "services-icons/dev-4.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    devIcon5: file(relativePath: {eq: "services-icons/dev-5.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    devIcon6: file(relativePath: {eq: "services-icons/dev-6.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    devIcon7: file(relativePath: {eq: "services-icons/dev-7.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    devIcon8: file(relativePath: {eq: "services-icons/dev-8.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    devIcon9: file(relativePath: {eq: "services-icons/dev-9.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    devIcon10: file(relativePath: {eq: "services-icons/dev-10.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    devIcon11: file(relativePath: {eq: "services-icons/dev-11.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    devIcon12: file(relativePath: {eq: "services-icons/dev-12.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    graphics1: file(relativePath: {eq: "services-icons/graphics-1.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    graphics2: file(relativePath: {eq: "services-icons/graphics-2.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    graphics3: file(relativePath: {eq: "services-icons/graphics-3.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    graphics4: file(relativePath: {eq: "services-icons/graphics-4.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    web1: file(relativePath: {eq: "services-icons/web-1.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    web2: file(relativePath: {eq: "services-icons/web-2.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    },
  }
  
`;

export default Services